/* eslint-disable eol-last */
/* eslint-disable import/prefer-default-export */

/**
 * accepts an array with duplicates and returns an array with unique values
 * @param {array} array
 * @returns array with unique values
 * In ES6 you have Sets and Spread which makes it very easy and performant to remove all duplicates
 * Reference: https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
 */

function uniqueArray(array) {
    const newArray = [...new Set(array)]

    return newArray
}

export default uniqueArray