// Please make sure to have videoID as a string and not a number.

const tours = [{
    name: '/home',
    url: '',
    tour: 'homeTour',
    videoId: 701493994,
    title: 'Homepage',
    description: 'Here you can find the status of your Prescription',
    length: '01:21',
    character: require('@/assets/images/screenshots/homeTour.png'),
    characterSize: 300,
    series: 1
},

]

export default tours
