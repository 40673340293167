<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <!-- <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon> -->
          <v-btn
            fab
            large
            class="me-3"
            icon
            color="primary"
            :to="{ name: 'home'}"
          >
            <v-icon>
              {{ icons.mdiHome }}
            </v-icon>
          </v-btn>
          <!-- <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search> -->
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-help-button></app-bar-help-button>
          <!-- <cart-button></cart-button> -->
          <!-- <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher> -->
          <!-- <app-bar-notification></app-bar-notification> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>
    <universal-alert></universal-alert>
    <!-- <v-btn @click="loadZenDesk">
      Support
    </v-btn> -->
    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://pharmaguide.ca"
          class="text-decoration-none"
        >PharmaGuide Inc.</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
        <div class="align-center d-none d-md-flex">
          <span>Hand-crafted &amp; Made with</span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template>
    <!-- <template #v-app-content>
      <app-customizer class="d-none d-md-block"></app-customizer>
    </template> -->
  </layout-content-vertical-nav>
</template>

<script>
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import { mdiMenu, mdiHeartOutline, mdiHome } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { ref, watch } from '@vue/composition-api'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import AppBarHelpButton from '@/components/app-bar/AppBarHelpButton.vue'
import UniversalAlert from '@/components/UI/UniversalAlert.vue'

// import { jwt } from 'jsonwebtoken'
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'
import { useVisitStore } from '@/stores/Visit.js'

// App Bar Components
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import cartButton from '@/components/OrderCart/cartButton.vue'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data.js'

export default {
    components: {
        LayoutContentVerticalNav,
        UniversalAlert,

        // App Bar Components
        AppBarHelpButton,
        AppBarSearch,
        AppBarUserMenu,
        AppBarNotification,
        AppBarThemeSwitcher,
        cartButton,
        AppCustomizer,

    },
    setup() {
        const $vuetify = getVuetify()
        const visitStore = useVisitStore()

        // Search
        const appBarSearchQuery = ref('')
        const shallShowFullSearch = ref(false)
        const maxItemsInGroup = 5
        const totalItemsInGroup = ref({
            pages: 0,
            files: 0,
            contacts: 0,
        })
        watch(appBarSearchQuery, () => {
            totalItemsInGroup.value = {
                pages: 0,
                files: 0,
                contacts: 0,
            }
        })

        // NOTE: Update search function according to your usage
        const searchFilterFunc = (item, queryText, itemText) => {
            if (item.header || item.divider) return true

            const itemGroup = (() => {
                if (item.to !== undefined) return 'pages'
                if (item.size !== undefined) return 'files'
                if (item.email !== undefined) return 'contacts'

                return null
            })()

            const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

            if (isMatched) {
                if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
                else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
                else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
            }

            return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
        }

        // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
        const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
            if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
                toggleVerticalNavMenuActive()
            }
        }

        return {
            visitStore,
            navMenuItems,
            handleShallShowFullSearchUpdate,

            // Search
            appBarSearchQuery,
            shallShowFullSearch,
            appBarSearchData,
            searchFilterFunc,

            icons: {
                mdiMenu,
                mdiHeartOutline,
                mdiHome
            },
        }
    },
    data() {
        return {}
    },

    //   computed: {
    //     payload() {
    //       return {
    //         name: this.visitStore.patientData.name,
    //         email: this.visitStore.patientData.email,
    //         iat: Math.floor(new Date().getTime() / 1000),
    //         exp: Math.floor(new Date().getTime() / 1000) + 60 * 7,
    //         jti: this.visitStore.patientData.id,
    //       }
    //     },
    //     token() {
    //       const jwt = require('jsonwebtoken')

    //       const data = jwt.sign(this.payload, '577F3DA417F88C87DA6F07D0585AE88941C3472781480B58A24B560E6887E76C')

    //       return data
    //     },
    //   },
    mounted() {
        setTimeout(() => {
            this.$zendesk.$on('open', () => {
                if (this.visitStore.visitData) {
                    this.$zendesk.identify({
                        name: this.visitStore.patientData.name,
                        email: this.visitStore.patientData.email,
                    })
                }
            })
        }, 5000)

    // this.$zendesk.load('ead6c341-7c18-4a84-b096-6cbf7c2beb5c')
    // this.$zendesk.identify({
    //   name: this.visitStore.patientData.name,
    //   email: this.visitStore.patientData.email,
    //   //   phone: this.visitStore.patientData.phoneNumber,
    // })
    // this.$zendesk.zE('webWidget', 'show')
    // this.$zendesk.updateSettings({
    //   webWidget: {
    //     authenticate: {
    //       chat: {
    //         jwtFn() {
    //           return this.token
    //         },
    //       },
    //     },
    //   },
    // })
    // console.log(this.$zendesk)
    // this.$zendesk.show()
    // this.$zendesk.$on('open', () => {
    //   this.$zendesk.zE('webWidget', 'updateSettings', {
    //     webWidget: {
    //       authenticate: {
    //         chat: {
    //           jwtFn(callback) {
    //             // Fetch your jwt token and then call our supplied callback below.
    //             callback(this.token)
    //           },
    //         },
    //       },
    //     },
    //   })
    // })
    // this.$zendesk.$on('open', () => {
    // .then(() => {
    //   this.$zendesk.set('external_id', this.visitStore.patientData.id)
    // })
    //   zE('webWidget', 'updateSettings', {
    //     webWidget: {
    //       authenticate: {
    //         chat: {
    //           jwtFn(callback) {
    //             callback(this.token)
    //           },
    //         },
    //       },
    //     },
    //   })
    //   zE('webWidget', 'updateSettings', {
    //     webWidget: {
    //       chat: {
    //         prechatForm: {
    //           greeting: {
    //             '*': 'Please fill out the form below to chat with us',
    //           },
    //         },
    //       },
    //     },
    //   })
    //   this.$zendesk.identify({
    //     // message: this.visitStore.patientData.id,
    //     name: this.visitStore.patientData.name,
    //     email: this.visitStore.patientData.email,
    //     phone: this.visitStore.patientData.phoneNumber,
    //   })
    //   zE('webWidget', 'prefill', {
    //     name: {
    //       value: this.visitStore.patientData.name,
    //       readOnly: true, // optional
    //     },
    //     email: {
    //       value: this.visitStore.patientData.email,
    //       readOnly: true, // optional
    //     },
    //     phone: {
    //       value: this.visitStore.patientData.phoneNumber,
    //       readOnly: true, // optional
    //     },
    //     message: {
    //       value: this.visitStore.patientData.phoneNumber,
    //       readOnly: true, // optional
    //     },
    //   })
    //   this.$zendesk.updateSettings('webWidget', 'updateSettings', {
    //     webWidget: {
    //       chat: {
    //         departments: {
    //           enabled: ['finance', 'hr', 'sales'],
    //           select: 'sales',
    //         },
    //       },
    //     },
    //   })
    //   this.$zendesk.updateSettings({
    //     webWidget: {
    //       contactForm: {
    //         fields: [
    //           { id: 'description', prefill: { '*': 'My field text' } },
    //           { id: 2142225, prefill: { '*': 'My custom field text' } },
    //         ],
    //       },
    //     },
    //   })
    //   console.log('Widget is open', this.visitStore.visitId)
    // })
    // this.$zendesk.$on('close', () => {
    //   console.log('Widget is closed')
    // })
    },
    methods: {
        loadZenDesk() {
            this.$zendesk.load('ead6c341-7c18-4a84-b096-6cbf7c2beb5c')
            this.$zendesk.identify({
                name: this.visitStore.patientData.name,
                email: this.visitStore.patientData.email,

                // phone: this.visitStore.patientData.phoneNumber,
                // notes: this.visitStore.patientData.id,
            })

            //   this.$zendesk.zE('webWidget', 'updateSettings', {
            //     webWidget: {
            //       authenticate: {
            //         jwtFn(callback) {
            //           // Fetch your jwt token and then call our supplied callback below.
            //           callback(this.token)
            //         },
            //       },
            //     },
            //   })
            this.$zendesk.zE.activate()

            //   console.log(this.$zendesk.zE('webWidget', 'updateSettings')) // .authentication.chat.jwtFn()
        },
    },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
