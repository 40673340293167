// use src\@core\layouts\composable\vertical-nav\useVerticalNavMenu.js to adjust the function that builds this along with this file:
// src\@core\layouts\components\vertical-nav-menu\VerticalNavMenu.vue

import misc from '@/router/misc'
// eslint-disable-next-line import/order
import {
    mdiAccountOutline, mdiAlertDecagram, mdiFaceAgent, mdiFax, mdiHistory, mdiHomeOutline
} from '@mdi/js'

export default [
    {
        title: 'Home',
        icon: mdiHomeOutline,
        to: 'home',
        hideOnNavBar: null,
    },

    // {
    //     title: 'Visit',
    //     icon: mdiAccountHeart,
    //     to: 'visit',
    //     hideOnNavBar: null,
    // },
    {
        title: 'My Profile',

        // to: { name: 'apps-user-view', params: { id: 21 } },
        to: 'apps-user-view',
        icon: mdiAccountOutline,
        hideOnNavBar: true,
    },
    {
        title: 'Transmit out',
        icon: mdiFax,
        to: 'transfer',
        hideOnNavBar: true,
    },
    {
        title: 'Fill',
        icon: mdiAlertDecagram,
        to: 'enrollment',
        hideOnNavBar: true,
    },
    {
        title: 'Counselling',
        icon: mdiFaceAgent,
        to: 'counselling',
        hideOnNavBar: true,
    },
    {
        title: 'Rx History',
        icon: mdiHistory,
        hideOnNavBar: true,
    },

    {
        title: misc[0].meta.title,
        to: misc[0].name,
        icon: misc[0].meta.icon,
        hideOnNavBar: misc[0].meta.hideOnNavBar,
    },
    {
        title: misc[1].meta.title,
        to: misc[1].name,
        icon: misc[1].meta.icon,
        hideOnNavBar: misc[1].meta.hideOnNavBar,
    },
    {
        title: misc[2].meta.title,
        to: misc[2].name,
        icon: misc[2].meta.icon,
        hideOnNavBar: misc[2].meta.hideOnNavBar,
    },

    //   ...misc,

    //   {
    //     title: 'User',
    //     icon: mdiAccountOutline,
    //     children: [
    //       {
    //         title: 'User List',
    //         to: 'apps-user-list',
    //       },
    //       {
    //         title: 'User View',
    //         to: { name: 'apps-user-view', params: { id: 21 } },
    //       },
    //     ],
    //   },

    //   {
    //     title: 'New Fill',
    //     icon: mdiPrescription,
    //     to: 'enrollment',
    //   },
    //   {
    //     title: 'Refill',
    //     icon: mdiCalendarSync,
    //     to: 'enrollment',
    //   },
]
