function getApi() {
    // const debug = process.env.VUE_APP_DEV_MODE

    const debug = true

    if (process.env.NODE_ENV === 'development' && debug === true) {
        return 'http://localhost:3001'
    }

    return process.env.VUE_APP_API_URL
}

const api = getApi()

export default {
    api,
}

// 1 liner
// api = (process.env.NODE_ENV === 'development' && debug === 'true')  ? 'http://localhost:3001' : process.env.VUE_APP_API_URL
