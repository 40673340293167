<template>
  <v-dialog
    v-model="isDialogVisible"
    fullscreen
    width="70vh"
  >
    <template #activator="{ on, attrs }">
      <v-badge
        overlap
        content="3"
        color="error"
      >
        <v-btn
          color="primary"
          dark
          fab
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            {{ icons.mdiCart }}
          </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <order-cart></order-cart>
    </v-card>
    <!-- <v-card>
      <order-cart></order-cart>
      <v-card-title>
        Privacy Policy
      </v-card-title>

      <v-card-text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="isDialogVisible = false"
        >
          I accept
        </v-btn>
      </v-card-actions>
    </v-card> -->
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCart } from '@mdi/js'
import orderCart from '@/components/OrderCart/orderCart.vue'

export default {
    name: 'CartButton',
    components: {
        orderCart
    },
    setup() {
        const isDialogVisible = ref(false)

        return {
            isDialogVisible,
            icons: {
                mdiCart,
            },
        }
    },
}
</script>
