<template>
  <div class="2xl:tw-container 2xl:tw-mx-auto">
    <div class="lg:tw-px-20 md:tw-px-6 tw-px-4 md:tw-py-12 tw-py-8">
      <div class="tw-flex tw-items-center tw-space-x-4">
        <h1 class="tw-text-3xl lg:tw-text-4xl tw-font-semibold tw-text-gray-800">
          Order: Item list
        </h1>
        <p class="tw-text-base tw-text-gray-600 tw-mt-4">
          (02 Items)
        </p>
      </div>

      <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-strech tw-mt-10 md:tw-mt-14 lg:tw-mt-16">
        <div>
          <img
            src="https://i.ibb.co/JxRLmgC/pexels-melvin-buezo-2529148-1-1.png"
            alt="A pair of gray sneakers"
            role="img"
            class="tw-hidden md:tw-block"
          />
          <img
            src="https://i.ibb.co/VNZTzjQ/pexels-melvin-buezo-2529148-3.png"
            alt="A pair of gray sneakers"
            role="img"
            class="tw-w-full tw-h-full md:tw-hidden"
          />
        </div>
        <div class="md:tw-flex tw-hidden tw-w-full tw-justify-between">
          <div class="tw-flex tw-flex-col tw-justify-center md:tw-ml-6 lg:tw-ml-8">
            <p class="tw-text-sm tw-text-gray-800">
              #1090
            </p>
            <h2 class="tw-mt-1 tw-text-xl tw-font-semibold tw-text-gray-800">
              Running shoes
            </h2>
            <p class="tw-mt-4 tw-text-sm tw-leading-tight tw-text-gray-600 md:tw-w-8/12 lg:tw-w-10/12">
              If you are going to use a passage of Lorem Ipsum,you need to be sure there isn't anything.
            </p>
            <div class="tw-mt-8 tw-flex tw-space-x-6 tw-items-center">
              <a
                href="javascript:void(0)"
                class="tw-text-base tw-text-gray-600 tw-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-text-black"
              >Edit</a>
              <a
                href="javascript:void(0)"
                class="tw-text-base tw-text-gray-600 tw-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-text-black"
              >Remove</a>
              <a
                href="javascript:void(0)"
                class="tw-text-base tw-text-gray-600 tw-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-text-black"
              >Move to wishlist</a>
            </div>
          </div>
          <div>
            <div class="tw-flex md:tw-flex-col tw-h-full lg:tw-flex-row lg:tw-space-x-72">
              <div class="tw-p-3 tw-w-20 tw-h-10 tw-border tw-border-gray-300 tw-flex tw-items-center tw-justify-center tw-space-x-3">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      small
                      icon
                      class="focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-bg-gray-100"
                      color="primary"
                      v-bind="attrs"
                      @click="decrement('quantity1')"
                      v-on="on"
                    >
                      <v-icon>
                        {{ icons.mdiChevronLeft }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Decrease quantity</span>
                </v-tooltip>
                <p class="tw-pt-4 tw-text-lg tw-quantity1 tw-text-gray-800">
                  1
                </p>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      small
                      icon
                      class="focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-bg-gray-100"
                      color="primary"
                      v-bind="attrs"
                      @click="increment('quantity1')"
                      v-on="on"
                    >
                      <v-icon>
                        {{ icons.mdiChevronRight }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Increase quantity</span>
                </v-tooltip>
              </div>
              <div class="tw-hidden md:tw-block">
                <p class="tw-mt-24 lg:tw-mt-0 tw-text-xl tw-font-medium tw-text-gray-800 tw-text-right"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col md:tw-hidden tw-mt-4">
          <div class="tw-flex tw-items-center tw-justify-between">
            <div>
              <p class="tw-text-sm tw-text-gray-800">
                #1090
              </p>
              <h2 class="tw-mt-1 tw-text-xl tw-font-semibold tw-leading-tight tw-text-gray-800">
                Running shoes
              </h2>
            </div>
            <div class="tw-p-3 tw-w-20 tw-h-10 tw-border tw-border-gray-300 tw-flex tw-items-center tw-justify-center tw-space-x-3">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    small
                    icon
                    class="focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-bg-gray-100"
                    color="primary"
                    v-bind="attrs"
                    @click="decrement('quantity1')"
                    v-on="on"
                  >
                    <v-icon>
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Decrease quantity</span>
              </v-tooltip>
              <p class="tw-quantity1 tw-text-base tw-text-gray-800">
                1
              </p>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    small
                    icon
                    class="focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-bg-gray-100"
                    color="primary"
                    v-bind="attrs"
                    @click="increment('quantity1')"
                    v-on="on"
                  >
                    <v-icon>
                      {{ icons.mdiChevronRight }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Increase quantity</span>
              </v-tooltip>
            </div>
          </div>
          <p class="tw-mt-4 tw-w-11/12 tw-text-sm tw-leading-tight tw-text-gray-600">
            If you are going to use a passage of Lorem Ipsum,you need to be sure there isn't anything.
          </p>
          <div class="tw-mt-8 tw-flex tw-space-x-6 tw-items-center">
            <a
              href="javascript:void(0)"
              class="tw-text-base tw-text-gray-600 tw-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-text-black"
            >Edit</a>
            <a
              href="javascript:void(0)"
              class="tw-text-base tw-text-gray-600 tw-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-text-black"
            >Remove</a>
            <a
              href="javascript:void(0)"
              class="tw-text-base tw-text-gray-600 tw-underline focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600 hover:tw-text-black"
            >Move to wishlist</a>
          </div>
          <p class="tw-mt-10 tw-text-xl tw-font-medium tw-text-gray-800"></p>
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-flex-col-reverse md:tw-flex-row md:tw-justify-end tw-mt-16 md:tw-mt-36 lg:tw-mt-40 md:tw-space-x-6 tw-w-full">
        <a
          href="javascript:void(0)"
          class="tw-w-full md:tw-w-3/12 lg:tw-w-auto tw-flex tw-items-center tw-space-x-2 tw-mt-4 md:tw-mt-10 tw-justify-center focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-gray-600"
        >

          <v-icon class="tw-pb-3">
            {{ icons.mdiArrowLeft }}
          </v-icon>

          <p class="tw-text-base tw-font-medium tw-underline tw-text-gray-600 hover:tw-text-gray-900">Continue shopping</p>
        </a>
        <div class="tw-w-full md:tw-w-9/12 lg:tw-w-auto">
          <!-- <div class="tw-flex tw-items-center tw-justify-between">
            <h3 class="tw-text-2xl tw-text-gray-800">
              Total
            </h3>
            <p class="tw-text-2xl tw-font-semibold tw-text-gray-800">
              1000
            </p>
          </div> -->
          <button class="tw-w-full tw-mt-4 tw-bg-gray-800 hover:tw-bg-gray-900 tw-text-base tw-font-medium tw-leading-none tw-text-white tw-py-5 lg:tw-px-28 focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-800">
            Submit order
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mdiChevronLeft, mdiChevronRight, mdiArrowLeft } from '@mdi/js'
import { useVisitStore, useOrderStore } from '@/stores/index.js'

export default {
    name: 'Cart',
    data() {
        return {
            icons: {
                mdiChevronLeft,
                mdiChevronRight,
                mdiArrowLeft
            }
        }
    },
    setup() {
        const visitStore = useVisitStore()
        const orderStore = useOrderStore()
        const cart = JSON.parse(localStorage.getItem('cart')) || []
        const quantity = cart.reduce((acc, item) => acc + item.quantity, 0)
        const total = cart.reduce((acc, item) => acc + item.quantity * item.price, 0)

        return {
            visitStore,
            orderStore,
            cart,
            quantity,
            total
        }
    },
}
</script>
