/* eslint-disable import/named */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/order */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/extensions
import { defineStore } from 'pinia'

export const useOrderStore = defineStore('Order', {
    state: () => ({
        orderId: null,
        patientId: null,
        dob: null,
        valid: null,
        orderData: {},
        patientData: {},
        docData: {},
    }),

    getters: {
        getorderId: state => state.orderId,
        getDob: state => state.dob,
        getOrderData: state => state.orderData,
    },

    actions: {
        async setOrder(data) {
            try {
                localStorage.setItem('order', JSON.stringify(data))
                    .then(() => {
                        this.setOrderData(data)
                    })
                    .catch(e => {
                        throw new Error(e)
                    })
            } catch (e) {
                throw new Error(e)
            }
        },
        async setOrderData(data) {
            try {
                this.orderData = data
                this.orderId = data.id
                this.patientId = data.patientId
                this.dob = data.dob
                this.valid = data.valid
                this.patientData = data.patientData
                this.docData = data.docData
            } catch (e) {
                throw new Error(e)
            }
        },
        refreshOrderState() {
            this.orderId = null
        },
    },
})
