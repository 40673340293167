/* This is a route that is being exported.
Added Title and icon to automate Import into vertical Navigation Bar by using the onNavBar boolean
*/

// const dev = process.env.VUE_APP_HIDE_NAVIGATION
const prod = true
const dev = false

const misc = [
    {
        path: '/statusActionStepper',
        name: 'statusActionStepper',
        component: () => import('@/components/UI/StatusActionStepper.vue'),
        meta: {
            layout: 'blank',
            redirectIfLoggedOut: true,
            title: 'Status Action Stepper',
            icon: null,
            hideOnNavBar: prod,
        },
        children: [],
    },
    {
        path: '/forms/createprofileform',
        name: 'createProfileForm',
        component: () => import('@/components/Forms/createProfileForm.vue'),
        meta: {
            layout: 'blank',
            redirectIfLoggedOut: true,
            title: 'Create Profile Form',
            icon: null,
            hideOnNavBar: prod,
        },
        children: [],
    },
    {
        path: '/order/cart',
        name: 'orderCart',
        component: () => import('@/components/OrderCart/orderCart.vue'),
        meta: {
            layout: 'blank',
            redirectIfLoggedOut: true,
            title: 'Order Cart',
            icon: null,
            hideOnNavBar: prod,
        },
        children: [],
    },
]

export default misc
