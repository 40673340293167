<template>
  <div v-show="tour">
    <v-tooltip
      color="success"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          fab
          color="success"
          dark
          v-bind="attrs"
          v-on="on"
          @click="emitTour(tour)"
        >
          <v-icon>
            {{ icons.mdiHelp }}
          </v-icon>
        </v-btn>
      </template>
      <span>Help</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mdiHelp } from '@mdi/js'
import tours from '@/assets/product-tours.js'

export default {
    name: 'Helpbutton',
    data() {
        return {
            url: '',
            tour: {},
            tours,
            icons: {
                mdiHelp,
            },
        }
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                this.mapHelpTour()
            }
        },
    },
    mounted() {
        this.mapHelpTour()
    },
    methods: {
        mapHelpTour() {
            if (this.tours.find(({ name }) => name === this.$route.path)) {
                this.url = this.tours.find(({ name }) => name === this.$route.path).url
                this.tour = this.tours.find(({ name }) => name === this.$route.path)
            } else {
                this.url = null
                this.tour = null
            }
        },
        emitTour(tour) {
            this.$root.$emit('tour', tour)
        },
    },
}
</script>

<style scoped>
.v-btn--fab.v-size--default {
  height: 40px;
  width: 40px;
  margin-left: 16px;
}
</style>
