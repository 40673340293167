import Vue from 'vue'
import VueRouter from 'vue-router'
import misc from '@/router/misc.js'

// import { useVisitStore } from '@/stores/Visit'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'home',
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            layout: 'content',
            redirectIfLoggedOut: true,
        },
        children: [],
    },
    {
        path: '/transfer',
        name: 'transfer',
        component: () => import('@/views/Transfer.vue'),
        meta: {
            layout: 'content',
            redirectIfLoggedOut: true,
        },
    },
    {
        path: '/visit',
        name: 'visit',
        component: () => import('@/views/Visit.vue'),
        meta: {
            layout: 'content',
            redirectIfLoggedOut: true,
        },
    },
    {
        path: '/counselling',
        name: 'counselling',
        component: () => import('@/views/Counselling.vue'),
        meta: {
            layout: 'content',
            redirectIfLoggedOut: true,
        },
    },
    {
        path: '/enrollment',
        name: 'enrollment',
        component: () => import('@/views/Enrollment.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error404.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
        },
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'blank',

            //   resource: 'Public',
            //   redirectIfLoggedIn: true,
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
    {
        path: '/apps/user/list',
        name: 'apps-user-list',
        component: () => import('@/views/apps/user/user-list/UserList.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        // path: '/apps/user/view/:id',
        path: '/user/view',
        name: 'apps-user-view',
        component: () => import('@/views/apps/user/user-view/UserView.vue'),
        meta: {
            layout: 'content',
            redirectIfLoggedOut: true,
        },
    },
    ...misc,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
    //   const form = JSON.parse(localStorage.getItem('form'))

    const userData = localStorage.getItem('visitId')

    //   const isLoggedIn = userData && localStorage.getItem('valid') && localStorage.getItem('userAbility')

    const isLoggedIn = userData && localStorage.getItem('valid')

    //   console.log(to)

    //   if (to) {
    if (to.matched.some(route => route.meta.redirectIfLoggedIn)) {
        if (isLoggedIn) {
            next({ name: 'home' })
        } else {
            next()
        }
    } else if (to.matched.some(route => route.meta.redirectIfLoggedOut)) {
        if (isLoggedIn) {
            next()
        } else {
            next({ name: 'auth-login' })
        }
    } else {
        next()
    }

    //   }
    //   console.log('isLoggedIn', isLoggedIn)

    //   if (!canNavigate(to)) {
    // Redirect to login if not logged in
    //   if (!isLoggedIn) {
    //     return next({ name: 'auth-login' })
    //   }

    // If logged in => not authorized
    //   return next({ name: 'auth-login' })

    // return next({ name: 'misc-not-authorized' })
    //   }

    // Redirect if logged in
    //   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    //     next('/')
    //   }

    //   return next()
})

export default router
