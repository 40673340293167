/* eslint-disable eol-last */
/* eslint-disable import/prefer-default-export */

/**
 * It takes a string of numbers and formats it into a phone number format.
 * @param value - The value of the input field.
 * @returns The formatted phone number.
 */

function formatTelNum(value) {
    if (value) {
        const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        const formattedValue = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`

        return formattedValue
    }

    return ''
}

export default formatTelNum
