<template>
  <v-dialog
    v-model="alertStatus"
    width="500"
  >
    <div v-if="alertStatus">
      <v-alert
        :type="type"
      >
        <div v-html="message"></div>
      </v-alert>
    </div>
  </v-dialog>
</template>

<script>
export default {
    name: 'UniversalAlert',

    // props: {
    // // Options: info, success, warnings, error
    //     type: {
    //         type: String,
    //         default: 'info',
    //     },
    //     message: {
    //         type: String,
    //         default: " I'm a alert with a <strong>type</strong> of error",
    //     },
    //     alertStatus: {
    //         type: Boolean,
    //         default: false,
    //     },
    // },
    data() {
        return {
            type: 'info',
            message: " I'm a alert with a <strong>type</strong> of error",
            alertStatus: false,
            timer: 500
        }
    },
    watch: {
        alertStatus(val) {
            if (val) {
                // timer to close the alert
                setTimeout(() => {
                    this.alertStatus = false
                }, this.timer)
            }
        }
    },
    mounted() {
        this.$root.$on('alert', data => {
            this.type = data.type
            this.message = data.message
            this.alertStatus = true
            this.timer = data.timer || this.timer
        })

        // turn alertStatus off from an emit
        this.$root.$on('alert-off', () => {
            this.alertStatus = false
        })
    },
    setup() {},
}
</script>
