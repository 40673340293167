import { mdiHomeOutline, mdiFax, mdiAccountOutline } from '@mdi/js'

/* eslint-disable */
// prettier-ignore
export default [
    { header: 'Pages' },

    { title: 'Home', icon: mdiHomeOutline, to: 'home' },
    { title: 'Transfer', icon: mdiFax, to: 'transfer' },
    { title: 'Profile', icon: mdiAccountOutline, to: 'apps-user-view' },
]
