/**
 * It takes a string, splits it into an array of words, and returns an object with the last word as the
 * last name and the rest of the words as the first name
 * @returns An object with two properties, LastName and FirstName.
 */
const splitFullName = str => {
    if (typeof str === 'string' || str instanceof String) {
        const fullName = str.trim().split(' ')
        const LastName = fullName[fullName.length - 1]
        const FirstName = fullName.slice(0, fullName.length - 1).join(' ')

        return { LastName, FirstName }
    }

    return str
}

export default splitFullName
