/**
 * It takes a timestamp in the format of [year-month-day, hour:minute:second, year-month-day,
 * hour:minute:second] and returns the difference between the two timestamps in milliseconds.
 * @param timeStamp - [
 * @returns The time difference between the two dates.
 */
function getTimeDifference(timeStamp) {
    if (!timeStamp) {
        return 0
    }
    const unixTimeNow = Date.now()

    const unixTimeStamp = Number(timeStamp)
    const timeDifference = unixTimeNow - unixTimeStamp

    const timeDifferenceInMinutes = timeDifference / 1000 / 60

    return timeDifferenceInMinutes
}

export default getTimeDifference
