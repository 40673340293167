<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            id="avatarImage"
            :src="initialPng"
          ></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              id="avatarImage"
              :src="initialPng"
            ></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ visitStore.patientData.name }} </span>
          <small class="text--disabled text-capitalize">Patient</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item :to="{ name: 'apps-user-view', params: { id: 21 } }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Email -->
      <!-- <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- Chat -->
      <!-- <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Chat</v-list-item-title>
        </v-list-item-content> -->

      <!-- <v-list-item-action>
          <v-badge
            inline
            color="error"
            content="2"
          >
          </v-badge>
        </v-list-item-action> -->
      <!-- </v-list-item> -->

      <!-- <v-divider class="my-2"></v-divider> -->

      <!-- Settings -->
      <!-- <v-list-item href="#">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- FAQ -->
      <!-- <v-list-item>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
    mdiAccountOutline,
    mdiEmailOutline,
    mdiCheckboxMarkedOutline,
    mdiChatOutline,
    mdiCogOutline,
    mdiCurrencyUsd,
    mdiHelpCircleOutline,
    mdiLogoutVariant,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import Avatar from 'avatar-initials'
import { useVisitStore } from '@/stores/Visit'

export default {
    name: 'UserMenuButton',
    data() {
        return {
            //   initialPng: '',
        }
    },
    computed: {
        initialPng() {
            return Avatar.initialAvatar({
                useGravatar: false,
                size: 40,
                initials: this.visitStore.getPatInitials,
                initial_fg: '#ffffff',
                initial_bg: '#e0cffe',
                initial_size: 25, // Defaults to height / 2
                initial_weight: 500,
                initial_font_family: "'Lato', 'Lato-Regular', 'Helvetica Neue'",
            })
        },
    },
    mounted() {
    // console.log(this.visitStore.getPatInitials)
    },

    methods: {
        getAvatar() {
            this.initialPng = Avatar.initialAvatar({
                useGravatar: false,
                size: 80,
                initials: this.visitStore.getPatInitials,
            })
        },
    },

    setup() {
        const visitStore = useVisitStore()

        const { router } = useRouter()
        const logoutUser = () => {
            //   localStorage.removeItem('patData')
            localStorage.removeItem('valid')
            localStorage.removeItem('form')
            localStorage.removeItem('visitId')

            //   localStorage.removeItem('faxStatus')
            //   localStorage.removeItem('visitData')

            // Redirect to login page
            router.push({
                name: 'auth-login',
            })
        }

        return {
            visitStore,
            logoutUser,
            icons: {
                mdiAccountOutline,
                mdiEmailOutline,
                mdiCheckboxMarkedOutline,
                mdiChatOutline,
                mdiCogOutline,
                mdiCurrencyUsd,
                mdiHelpCircleOutline,
                mdiLogoutVariant,
            },
        }
    },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}

#avatarImage {
  padding: 2px;
  border: 2px solid #e0cffe;
  border-radius: 100%;
  box-shadow: 0 0 4px RGBA(51, 153, 204, 0.4);
}
</style>
