import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const themeConfig = {
    app: {
        name: '<span class="tw-text-blue-500">Phox</span> <span class="tw-text-green-500">Forms<span></span>',
        logo: require('@/assets/images/logos/phox.png'),
        isDark: false,
        isRtl: false,
        contentLayoutNav: 'vertical', // vertical, horizontal
        routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
        // ! `semi-dark` isn't available for horizontal nav menu
        skinVariant: 'default', // default, bordered, semi-dark
        contentWidth: 'full',
    },
    menu: {
        isMenuHidden: true,
        isVerticalNavMini: false,
        verticalMenuAccordion: true,
        groupChildIcon: mdiCheckboxBlankCircleOutline,
        horizontalNavMenuGroupOpenOnHover: true,
    },
    appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
        type: 'fixed', // fixed, static, hidden
        isBlurred: true,
    },
    footer: {
        type: 'static', // fixed, static, hidden
    },
    themes: {
        light: {
            primary: '#2084f7',
            accent: '#0d6efd',
            secondary: '#152451',
            success: '#56CA00',
            info: '#16B1FF',
            warning: '#FFB400',
            error: '#FF4C51',
            pglightblue: '#4a6d88',
            white: '#ffffff',
            grey: '#5c5c5c',
            orange: '#e3a436',
            pgfaintblue: '#88a2bc',
            fieldBg: '#faf6f0'
        },
        dark: {
            primary: '#2084f7',
            accent: '#0d6efd',
            secondary: '#152451',
            success: '#56CA00',
            info: '#16B1FF',
            warning: '#FFB400',
            error: '#FF4C51',
            fieldBg: '#faf6f0'
        },
    },
}

export default themeConfig
