import axios from 'axios'
import FormData from 'form-data'
import config from './config.js'

// eslint-disable-next-line no-multi-spaces, prefer-destructuring
const  api  = config.api

const getVisit = async formData => {
    try {
        const res = await axios.post(
            `${api}/visit`,
            {
                formData,
            },
            {
                headers: {
                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getVisitPat = async formData => {
    try {
        const res = await axios.post(
            `${api}/visit/pat`,
            {
                formData,
            },
            {
                headers: {
                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getAllVisitsByPt = async formData => {
    try {
        const res = await axios.post(
            `${api}/visit/pat/all`,
            {
                formData,
            },
            {
                headers: {
                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const getFaxStatus = async formData => {
    try {
        const res = await axios.get(`https://eoa42fyh41y5kft.m.pipedream.net?faxId=${formData.id}`, {
            headers: {
                //   Authorization: `Bearer ${accessToken}`,
            },
        })

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const sendFax = async formData => {
    try {
        const res = await axios.post(
            'https://eoy031p11t3lz7d.m.pipedream.net',
            {
                formData,
            },
            {
                headers: {
                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const updatePatientProfile = async formData => {
    try {
        const res = await axios.post(
            `${api}/updatePatientProfile`,
            {
                formData,
            },
            {
                headers: {
                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const updateVisitStatus = async formData => {
    try {
        const res = await axios.post(
            `${api}/updateVisitStatus`,
            {
                formData,
            },
            {
                headers: {
                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const updateVisitTransferPharmacy = async formData => {
    try {
        const res = await axios.post(
            `${api}/updateVisitTransferPharmacy`,
            {
                formData,
            },
            {
                headers: {
                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const resetVisitTransferPharmacy = async formData => {
    try {
        const res = await axios.post(
            `${api}/resetVisitTransferPharmacy`,
            {
                formData,
            },
            {
                headers: {
                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const updatePtUploadRecord = async formData => {
    const data = new FormData()
    data.append('file', formData.file)
    data.append('formType', formData.formType)
    data.append('patientId', formData.patientId)
    data.append('dateofBirth', formData.dateofBirth)
    try {
        const res = await axios.post(
            `${api}/file/upload`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',

                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const downloadPtFile = async formData => {
    const data = new FormData()
    data.append('fileName', formData.fileName)
    data.append('patientId', formData.patientId)
    data.append('dateofBirth', formData.dateofBirth)
    try {
        const res = await axios.post(
            `${api}/file/download`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',

                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

const deletePtFile = async formData => {
    const data = new FormData()
    data.append('fileName', formData.fileName)
    data.append('patientId', formData.patientId)
    data.append('dateofBirth', formData.dateofBirth)
    try {
        const res = await axios.post(
            `${api}/file/delete`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',

                    //   Authorization: `Bearer ${accessToken}`,
                },
            },
        )

        return res.data
    } catch (e) {
        throw new Error(e)
    }
}

export default {
    getVisit,
    getFaxStatus,
    sendFax,
    getVisitPat,
    updatePatientProfile,
    getAllVisitsByPt,
    updatePtUploadRecord,
    deletePtFile,
    downloadPtFile,
    updateVisitStatus,
    updateVisitTransferPharmacy,
    resetVisitTransferPharmacy,
}
