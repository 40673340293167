/* eslint-disable import/named */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/order */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/extensions
import { visitService } from '@/api/index'
import { getInitials } from '@/util/functions/index.js'
import { defineStore } from 'pinia'
import router from '@/router/index.js'

export const useVisitStore = defineStore('Visit', {
    state: () => ({
        visitId: null,
        patientId: null,
        dob: null,
        valid: null,
        visitData: {},
        faxStatus: {},
        patientData: {},
        docData: {},
        visitList: [],
    }),

    getters: {
        getvisitId: state => state.visitId,
        getDob: state => state.dob,
        getVisitData: state => state.visitData,
        getPatInitials: state => getInitials(state.patientData?.name),
        getForm: state => {
            const form = {
                valid: state.valid,
                id: state.visitId,
                fileName: `rx-${state.visitId}.pdf`,
                dob: state.dob,
            }

            return form
        },
    },

    actions: {
        async setVisit(data) {
            try {
                const form = {
                    valid: false,
                    id: data.id,
                    fileName: `rx-${data.id}.pdf`,
                    dob: data.dob,
                }
                localStorage.setItem('form', JSON.stringify(form))
                await visitService
                    .getVisit(form)
                    .then(res => {
                        if (res.status === 'invalid') {
                            router.app.$root.$emit('show-error-bar')
                        } else {
                            this.visitData = res.data[0]
                            this.faxStatus = res.faxStatus[0]
                            this.docData = res.docData[0]
                            this.valid = res.valid
                            this.dob = form.dob
                            this.visitId = form.id
                            this.patientId = res.data[0].patientId
                            const Updatedform = {
                                valid: res.valid,
                                id: form.id,
                                fileName: `rx-${form.id}.pdf`,
                                dob: form.dob,
                            }
                            localStorage.setItem('form', JSON.stringify(Updatedform))
                            localStorage.setItem('valid', res.valid)
                            localStorage.setItem('visitId', form.id)
                        }
                    })
                    .then(() => {
                        this.setPatientData()
                    })
                    .catch(e => {
                        throw new Error(e)
                    })
            } catch (e) {
                throw new Error(e)
            }
        },
        async setPatientData() {
            try {
                await visitService.getVisitPat({ patientId: this.patientId, dob: this.dob }).then(res => {
                    this.patientData = res[0]
                })
            } catch (e) {
                throw new Error(e)
            }
        },
        async ptVisitList(data) {
            try {
                await visitService.getAllVisitsByPt({ patientId: this.patientId, dob: this.dob }).then(res => {
                    this.visitList = res.data
                })
            } catch (e) {
                throw new Error(e)
            }
        },
        async setFaxStatus(data) {
            try {
                this.faxStatus = data
            } catch (e) {
                throw new Error(e)
            }
        },
        refreshState() {
            this.visitId = null
        },
    },
})
