/**
 * It takes a string, splits it into an array of words, takes the first and last word, takes the first
 * letter of each word, and returns the initials in uppercase.
 * @returns The first letter of the first name and the first letter of the last name.
 */
const getInitials = str => {
    if (typeof str === 'string' || str instanceof String) {
        const fullName = str.trim().split(' ')
        const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0)

        return initials.toUpperCase()
    }

    return str
}

export default getInitials
